import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from 'semantic-ui-react'

const LogoutButton = () => {
    const { logout } = useAuth0();

    return (
        <Button
            content="Log Out"
            type="submit"
            labelPosition='right'
            icon='hand point left outline'
            onClick={() => logout({ returnTo: window.location.origin })}
            floated='right'
            negative
            color='black'
        />
    );
};

export default LogoutButton;