import React, { useEffect, useState } from 'react';
import objectivedelta from '../api/objectivedelta';
import PropCard from './PropCard';
import { useAuth0 } from "@auth0/auth0-react";
import AddProp from './AddProp'

const Props = () => {
    const [props, setProps] = useState([]);
    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {

        const getProps = async () => {

            const accessToken = await getAccessTokenSilently({
                audience: "https://api.objectivedelta.com",
                scope: "write:props",
            });


            const { data } = await objectivedelta.get('/prop', {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                }
            })
            setProps(data)
        }

        getProps();
    }, [])

    const propList = props.map((prop) => {
        return <PropCard prop={prop} key={prop.prop_id} />
    })

    return (
        <div className="ui cards">
            {propList}
            <AddProp />
        </div >
    )
}

export default Props