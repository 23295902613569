import React from 'react';
import Route from './components/Route';
import Header from './components/Header';
// import Placeholder from './components/Placeholder';
import Props from './components/Props'
import Profile from './components/Profile'
import HomeMap from './components/HomeMap'


const App = () => {

    return (
        <div>
            <Header />
            <Route path='/'>
                <HomeMap />
            </Route>
            <Route path='/props'>
                <Props />
            </Route>
            <Route path='/profile'>
                <Profile />
            </Route>
        </div>
    );
}

export default App