import React, { useState } from 'react';
import { Button, Header, Modal, Form, Card } from 'semantic-ui-react'
import objectivedelta from '../api/objectivedelta';
import { useAuth0 } from "@auth0/auth0-react";

const AddProp = () => {
    const [open, setOpen] = useState(false)
    const { getAccessTokenSilently } = useAuth0();


    const handleSubmit = async (e) => {
        e.preventDefault();

        const fields = e.target.form.elements
        const workingConfig = {
            type: fields.type.value,
            name: fields.name.value,
            org_id: 'placeholder',
        }

        const accessToken = await getAccessTokenSilently({
            audience: "https://api.objectivedelta.com",
            scope: "write:props",
        });

        await objectivedelta.post(`/prop`, workingConfig,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                }
            })

        setOpen(false)
    }

    const handleOpen = async () => {
        setOpen(true)
    }

    return (
        <Card>
            <Card.Content>
                <Card.Header>
                    Add New Prop
                </Card.Header>
            </Card.Content>
            <Card.Content extra>
                <Modal
                    onClose={() => setOpen(false)}
                    onOpen={handleOpen}
                    open={open}
                    trigger={<Button basic color='blue'>Configure</Button>}
                    as={Form}
                >
                    <Modal.Header>Create new Prop</Modal.Header>
                    <Modal.Content image>
                        <Modal.Description>
                            <Header>Input</Header>
                            <Form.Field
                                label="Name"
                                type="text"
                                control="input"
                                id="name"
                            />
                            <Form.Field
                                label="Type"
                                type="text"
                                control="input"
                                id="type"
                            />
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='black' onClick={() => setOpen(false)} type='button'>
                            Cancel
                        </Button>
                        <Button
                            content="Submit"
                            type="submit"
                            labelPosition='right'
                            icon='checkmark'
                            onClick={handleSubmit}
                            positive
                        />
                    </Modal.Actions>
                </Modal>
            </Card.Content>
        </Card >
    )
}

export default AddProp