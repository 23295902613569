import React, { useState, useEffect } from 'react';
import { Button } from 'semantic-ui-react'
import objectivedelta from '../api/objectivedelta';
import { useAuth0 } from "@auth0/auth0-react";




const UpdatePropGeoButton = (p) => {
    const [position, setPosition] = useState({});
    const { getAccessTokenSilently } = useAuth0();

    const updateGeo = async (pid, lat, long) => {
        const accessToken = await getAccessTokenSilently({
            audience: "https://api.objectivedelta.com",
            scope: "write:propConfig",
        });

        const geoInput = {
            prop_id: pid,
            latitude: lat,
            longitude: long,
        }

        await objectivedelta.post(`/prop/${pid}/geo`, geoInput,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                }
            })
    }


    const handleUpdateLocation = async (e) => {
        e.preventDefault();

        window.navigator.geolocation.getCurrentPosition(
            (position) => setPosition(position.coords),
            (err) => console.log(err)
        );
    }

    useEffect(() => {
        if (typeof position.latitude !== 'undefined') {
            updateGeo(p.propID, position.latitude, position.longitude)
        }
    }, [position, p]);


    return (
        <Button basic color='green' onClick={handleUpdateLocation}>
            Update GPS
        </Button>
    )

}

export default UpdatePropGeoButton