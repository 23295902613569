import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Auth0Provider } from "@auth0/auth0-react";


// ReactDOM.render(
//     <App />,
//     document.querySelector('#root')
// );

ReactDOM.render(
    <Auth0Provider
        domain="objectivedelta.eu.auth0.com"
        clientId="0eO6Ftjs7ONsdcReG2U68jlyesK4W5Xd"
        redirectUri={window.location.origin}
        audience="https://api.objectivedelta.com"
        scope="write:props write:propConfig read:propConfig read:events write:events"
    >
        <App />
    </Auth0Provider>,
    document.getElementById("root")
);

