import React, { useState } from 'react';
import { Button, Header, Modal, Form, Card } from 'semantic-ui-react'
import objectivedelta from '../api/objectivedelta';
import { useAuth0 } from "@auth0/auth0-react";
import UpdatePropGeoButton from "./UpdatePropGeoButton";

const PropCard = (p) => {
    const [open, setOpen] = useState(false)
    const [propConfig, setPropConfig] = useState({});
    const { getAccessTokenSilently } = useAuth0();

    const getPropConfig = async () => {

        const accessToken = await getAccessTokenSilently({
            audience: "https://api.objectivedelta.com",
            scope: "read:propConfig",
        });

        const { data } = await objectivedelta.get(`/prop/${p.prop.prop_id}/config`, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            }
        })
        if (data === null) {
            setPropConfig({
                max_score: 0,
                capture_time: 0,
                theme: '',
                code1: '',
                code2: '',
                opt1: '',
                opt2: '',
                team_id: '',
            })
        } else {
            setPropConfig(data)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const fields = e.target.form.elements
        const workingConfig = {
            prop_id: p.prop.prop_id,
            max_score: parseInt(fields.max_score.value),
            capture_time: parseInt(fields.capture_time.value),
            theme: fields.theme.value,
            code1: fields.code1.value,
            code2: fields.code2.value,
            opt1: parseInt(fields.opt1.value),
            opt2: parseInt(fields.opt2.value),
            team_id: fields.team_id.value,
        }

        const accessToken = await getAccessTokenSilently({
            audience: "https://api.objectivedelta.com",
            scope: "read:propConfig",
        });

        await objectivedelta.post(`/prop/${p.prop.prop_id}/config`, workingConfig,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                }
            })

        setOpen(false)
        getPropConfig();
    }

    const handleOpen = async () => {
        getPropConfig();

        setOpen(true)
    }


    return (
        <Card>
            <Card.Content>
                <Card.Header>
                    {p.prop.name}
                </Card.Header>
                <Card.Meta>
                    Type: {p.prop.type}
                </Card.Meta>
                <Card.Meta>
                    ID: {p.prop.prop_id}
                </Card.Meta>
                <Card.Description>
                    Objective delta prop
                </Card.Description>
            </Card.Content>
            <Card.Content extra>
                <div className='ui two buttons'>
                    <UpdatePropGeoButton propID={p.prop.prop_id} />
                    <Modal
                        onClose={() => setOpen(false)}
                        onOpen={handleOpen}
                        open={open}
                        trigger={<Button basic color='blue'>Configure</Button>}
                        as={Form}
                    >
                        <Modal.Header>Configure {p.prop.name}</Modal.Header>
                        <Modal.Content image>
                            <Modal.Description>
                                <Header>Configuration</Header>
                                <Form.Field
                                    label="Max Score"
                                    defaultValue={propConfig.max_score}
                                    type="number"
                                    control="input"
                                    id="max_score"
                                />
                                <Form.Field
                                    label="Capture Time"
                                    defaultValue={propConfig.capture_time}
                                    type="number"
                                    control="input"
                                    id="capture_time"
                                />
                                <Form.Field
                                    label="Theme"
                                    defaultValue={propConfig.theme}
                                    type="text"
                                    control="input"
                                    id="theme"
                                />
                                <Form.Field
                                    label="Code 1"
                                    defaultValue={propConfig.code1}
                                    type="text"
                                    control="input"
                                    id="code1"
                                />
                                <Form.Field
                                    label="Code 2"
                                    defaultValue={propConfig.code2}
                                    type="text"
                                    control="input"
                                    id="code2"
                                />
                                <Form.Field
                                    label="Option 1"
                                    defaultValue={propConfig.opt1}
                                    type="number"
                                    control="input"
                                    id="opt1"
                                />
                                <Form.Field
                                    label="Option 2"
                                    defaultValue={propConfig.opt2}
                                    type="number"
                                    control="input"
                                    id="opt2"
                                />
                                <Form.Field
                                    label="Team ID"
                                    defaultValue={propConfig.team_id}
                                    type="text"
                                    control="input"
                                    id="team_id"
                                />
                            </Modal.Description>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button color='black' onClick={() => setOpen(false)} type='button'>
                                Cancel
                            </Button>
                            <Button
                                content="Submit"
                                type="submit"
                                labelPosition='right'
                                icon='checkmark'
                                onClick={handleSubmit}
                                positive
                            />
                        </Modal.Actions>
                    </Modal>
                </div>
            </Card.Content>
        </Card >
    )
}

export default PropCard