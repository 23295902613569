import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { Icon } from "leaflet";
import objectivedelta from '../api/objectivedelta';


import icon from "./constants";

const respawnblue = new Icon({
    iconUrl: '/icons/respawn-blue.svg',
    iconSize: [40, 40]
})

const here = new Icon({
    iconUrl: '/icons/here.svg',
    iconSize: [40, 40]
})

const respawngray = new Icon({
    iconUrl: '/icons/respawn-gray.svg',
    iconSize: [40, 40]
})

const respawnred = new Icon({
    iconUrl: '/icons/respawn-red.svg',
    iconSize: [40, 40]
})

export default function HomeMap() {
    function LocationMarker() {
        const [position, setPosition] = useState(null);

        const map = useMap();

        useEffect(() => {
            const interval = setInterval(() => {
                map.locate().on("locationfound", function (e) {
                    setPosition(e.latlng);
                    map.flyTo(e.latlng, map.getZoom());
                })
            }, 1000);
            return () => clearInterval(interval)
        }, [map]);

        return position === null ? null : (
            <Marker position={position} icon={here}>
                <Popup>
                    You are here.
                </Popup>
            </Marker>
        );
    }

    function PropMarker() {
        const [positions, setPositions] = useState([]);

        useEffect(() => {
            const search = async () => {
                const { data } = await objectivedelta.get(`propgeo`, {})

                setPositions(data);
            };

            if (!positions.length) {
                search();
            } else {
                const timeoutId = setTimeout(() => {
                    search();
                }, 5000)

                return () => {
                    clearTimeout(timeoutId)
                }
            }

        }, [positions]);


        const renderedResults = positions.map((position) => {
            let curIcon = respawngray;
            switch (position.team_id) {
                case "0":
                    curIcon = respawngray
                    break;
                case "1":
                    curIcon = respawnred
                    break;
                case "2":
                    curIcon = respawnblue
                    break;
                default:
                    curIcon = respawngray

            }
            return (
                <Marker key={position.name} position={[position.latitude, position.longitude]} icon={curIcon}>
                    <Popup>
                        Name: {position.name} <br />
                        Type: {position.type} <br />
                    </Popup>
                </Marker>
            );
        })

        return (
            <React.Fragment>
                {renderedResults}
            </React.Fragment>
        );
    };

    return (
        <MapContainer
            center={[52.4260, 6.5371]}
            zoom={18}
            scrollWheelZoom
            style={{ height: "100vh" }}
        >
            <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <LocationMarker />
            <PropMarker />
        </MapContainer>
    );
}
