import React from 'react';
import Link from './Link';
import LoginButton from './LoginButton'
import LogoutButton from './LogoutButton'
import { useAuth0 } from "@auth0/auth0-react";
import { Menu } from 'semantic-ui-react';

const Header = () => {
    const { isAuthenticated } = useAuth0();

    return (
        <div className="ui secondary pointing menu">
            <Link href="/" className="item">
                Home
            </Link>
            {isAuthenticated ?
                <Link href="/props" className="item">
                    Props
                </Link> : null}
            {isAuthenticated ?
                <Link href="/profile" className="item">
                    Profile
                </Link> : null}
            <Menu.Menu position='right'>
                {isAuthenticated ? <LogoutButton /> : <LoginButton />}
            </Menu.Menu>
        </div>
    )
}

export default Header